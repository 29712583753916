import {propEq, find, pathOr} from 'ramda'

export const getDefaultSelected = (fieldName, search, options) => {
  switch (fieldName) {
    case 'filter.orderDisplayStatus': {
      return [
        ...(search['filter.orderDisplayStatus']
          ? search['filter.orderDisplayStatus'].map(value => find(propEq('value', value), options))
          : []),
      ]
    }
    case 'filter.tradePartnerExternalCompanyId': {
      return [
        ...(search['filter.tradePartnerExternalCompanyId']
          ? pathOr([], ['filter.tradePartnerExternalCompanyId'], search).map(optionString => {
              const [value, ...label] = optionString.split(':')
              return {label: label.join(':'), value}
            })
          : []),
      ]
    }
    case 'filter.productType': {
      return [
        ...(search['filter.productType']
          ? search['filter.productType'].map(value => find(propEq('value', value), options))
          : []),
      ]
    }
    default:
      return ''
  }
}
