import {aGenericError} from '@modifi/redux'
import {path, pick} from 'ramda'
import {getAuthenticatedAresClient} from '../../../lib'

import {aUpdateOrder} from './data.store'

export const ACTION_CANCEL_ORDER_CLEANUP = 'order/CANCEL_CLEANUP'
export const ACTION_CANCEL_ORDER_PENDING = 'order/CANCEL_PENDING'
export const ACTION_CANCEL_ORDER_SUCCESS = 'order/CANCEL_SUCCESS'
export const ACTION_CANCEL_ORDER_ERROR = 'order/CANCEL_ERROR'

const initialState = {
  cancelPending: false,
  cancelError: null,
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ACTION_CANCEL_ORDER_CLEANUP:
      return {
        ...state,
        cancelPending: false,
        cancelError: null,
      }
    case ACTION_CANCEL_ORDER_PENDING:
      return {
        ...state,
        cancelPending: true,
        cancelError: null,
      }
    case ACTION_CANCEL_ORDER_ERROR: {
      const {message} = payload
      return {
        ...state,
        cancelPending: false,
        cancelError: message,
      }
    }
    case ACTION_CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        cancelPending: false,
        cancelError: null,
      }
    default:
      return state
  }
}

export const aCancelOrderCleanup = () => ({
  type: ACTION_CANCEL_ORDER_CLEANUP,
})
export const aCancelOrder = externalOrderId => async dispatch => {
  dispatch({
    type: ACTION_CANCEL_ORDER_PENDING,
    payload: {externalOrderId},
  })

  let result = {}
  try {
    const client = getAuthenticatedAresClient()
    result = await client.Orders.cancelByRef({externalOrderId}).then(res => res.data())
  } catch (error) {
    dispatch(aGenericError(ACTION_CANCEL_ORDER_ERROR, error))
    throw error
  }

  dispatch({
    type: ACTION_CANCEL_ORDER_SUCCESS,
    payload: result,
  })

  dispatch(
    aUpdateOrder({
      ...pick(['cancelled', 'orderDisplayStatus', 'actions', 'cancelledOn', 'payments'], result),
    })
  )
}

export const selectIsCancelPending = state => path(['order', 'cancel', 'cancelPending'], state)
export const selectCancelError = state => path(['order', 'cancel', 'cancelError'], state)
