import {equals} from 'ramda'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {OrderDisplayStatus} from '@modifi/enums'
import {
  Row,
  Col,
  CheckboxFilter,
  SearchFilter,
  DateFilter,
  AmountFilter,
  ResetFilterButton,
  CheckboxPure,
} from '@modifi/ui'
import * as PropTypes from 'prop-types'

import {Wrapper, FilterCol, ResetCol} from './styles'
import {formatMomentToString} from '../../../utils/formatMomentToString'
import {getDefaultSelected} from '../utils/filterValues'
import {getRolesOptions} from '../constants'
import {SkeletonButton} from '../../../ui/skeleton-loaders'

const OrderFilterContainer = ({
  search,
  hasMultipleRoles,
  isMetaDataLoading,
  searchTradePartners,
  t,
  handleSubmit,
  filterCount,
  resetAllFilters,
  orderDisplayStatusOptions,
  hasMetaDataError,
  maxAmount,
  minAmount,
}) => {
  const [actionRequiredChecked, setActionRequiredChecked] = useState(!!search.hasActionRequired)
  const searchHasActionRequired = !!search.hasActionRequired
  const searchHasActionRequiredRef = useRef(searchHasActionRequired)

  useEffect(() => {
    // in case this filter got changed via quick filter
    if (searchHasActionRequired !== searchHasActionRequiredRef.current) {
      searchHasActionRequiredRef.current = searchHasActionRequired
      setActionRequiredChecked(searchHasActionRequired)
    }
  }, [searchHasActionRequired])

  const actionRequiredCheckedRef = useRef(actionRequiredChecked)
  useEffect(() => {
    if (actionRequiredCheckedRef.current !== actionRequiredChecked) {
      actionRequiredCheckedRef.current = actionRequiredChecked
      const timeout = setTimeout(() => {
        handleSubmit({
          hasActionRequired: actionRequiredChecked || null,
        })
      }, 100)
      return () => {
        clearTimeout(timeout)
      }
    }
    return undefined
  }, [actionRequiredChecked, handleSubmit, searchHasActionRequired])

  const handleTradePartnerSearch = useCallback(
    searchString =>
      searchTradePartners(searchString).then(tradePartners =>
        tradePartners.map(tradePartner => ({
          value: tradePartner.externalCompanyId,
          label: tradePartner.address?.companyName,
        }))
      ),
    [searchTradePartners]
  )

  const handleOrderDisplayStatusSubmit = useCallback(
    selected => {
      const searchParams = {
        'filter.orderDisplayStatus': selected.map(({value}) => value),
      }
      handleSubmit(searchParams)
    },
    [handleSubmit]
  )

  const handleOrderDisplayStatusReset = useCallback(() => {
    handleSubmit({'filter.orderDisplayStatus': null})
  }, [handleSubmit])

  const handleDateFilterSubmit = useCallback(
    selected => {
      const searchParams = {
        'filter.createdOn.from':
          typeof selected.from === 'string' || !selected.from
            ? selected.from
            : formatMomentToString(selected.from),
        'filter.createdOn.to':
          typeof selected.to === 'string' || !selected.to
            ? selected.to
            : formatMomentToString(selected.to),
      }
      handleSubmit(searchParams)
    },
    [handleSubmit]
  )

  const handleDateFilterReset = useCallback(
    selected => {
      handleSubmit({
        'filter.createdOn.from': selected.from,
        'filter.createdOn.to': selected.to,
      })
    },
    [handleSubmit]
  )

  const handleSearchFilterSubmit = useCallback(
    selected => {
      const searchParams = {
        'filter.tradePartnerExternalCompanyId': selected.map(
          ({value, label}) => `${value}:${label}`
        ),
      }
      handleSubmit(searchParams)
    },
    [handleSubmit]
  )

  const handleSearchFilterReset = useCallback(() => {
    handleSubmit({'filter.tradePartnerExternalCompanyId': null})
  }, [handleSubmit])

  const handleAmountFilterSubmit = useCallback(
    selected => {
      const searchParams = {
        'filter.amount.from': selected.from,
        'filter.amount.to': selected.to,
      }
      handleSubmit(searchParams)
    },
    [handleSubmit]
  )

  const handleAmountFilterReset = useCallback(
    () =>
      handleSubmit({
        'filter.amount.from': null,
        'filter.amount.to': null,
      }),
    [handleSubmit]
  )

  const handleRoleFilterSubmit = useCallback(
    selected => {
      const searchParams = {
        'filter.productType': selected.map(({value}) => value),
      }
      handleSubmit(searchParams)
    },
    [handleSubmit]
  )

  const handleRoleFilterReset = useCallback(() => {
    handleSubmit({'filter.productType': null})
  }, [handleSubmit])

  const handleActionRequiredFilterChange = useCallback(e => {
    const isChecked = e.target.checked
    setActionRequiredChecked(isChecked)
  }, [])

  if (hasMetaDataError) return null
  return (
    <Wrapper data-ident="order-filters" mt={20} py={15} px={20} mdPy={32} mdPx={32}>
      <Row>
        <Col col={10}>
          <Row alignItems="center">
            <FilterCol col="auto">
              {!isMetaDataLoading ? (
                <CheckboxFilter
                  options={orderDisplayStatusOptions}
                  fieldName={t('ol.filters.order-buyer-status.fieldName')}
                  dropdownPosition="left"
                  onSubmit={handleOrderDisplayStatusSubmit}
                  defaultSelected={getDefaultSelected(
                    'filter.orderDisplayStatus',
                    search,
                    orderDisplayStatusOptions
                  )}
                  onReset={handleOrderDisplayStatusReset}
                  dropdownWidth={250}
                />
              ) : (
                <SkeletonButton stringLength={13} />
              )}
            </FilterCol>
            <FilterCol col="auto">
              {!isMetaDataLoading ? (
                <DateFilter
                  fieldName={t('ol.filters.order-date.fieldName')}
                  defaultFrom={search['filter.createdOn.from']}
                  defaultTo={search['filter.createdOn.to']}
                  onSubmit={handleDateFilterSubmit}
                  onReset={handleDateFilterReset}
                  dropdownWidth={330}
                  startCalendarFrom="1990-01-01"
                />
              ) : (
                <SkeletonButton stringLength={13} />
              )}
            </FilterCol>
            <FilterCol col="auto">
              {!isMetaDataLoading ? (
                <SearchFilter
                  fieldName={t('ol.filters.trade-partner.fieldName')}
                  minLength={3}
                  onSearch={handleTradePartnerSearch}
                  defaultSelected={getDefaultSelected(
                    'filter.tradePartnerExternalCompanyId',
                    search
                  )}
                  onSubmit={handleSearchFilterSubmit}
                  onReset={handleSearchFilterReset}
                />
              ) : (
                <SkeletonButton stringLength={15} />
              )}
            </FilterCol>
            <FilterCol col="auto">
              {!isMetaDataLoading ? (
                <AmountFilter
                  fieldName={t('ol.filters.amount.fieldName')}
                  defaultFrom={search['filter.amount.from']}
                  defaultTo={search['filter.amount.to']}
                  onSubmit={handleAmountFilterSubmit}
                  onReset={handleAmountFilterReset}
                  maxValue={maxAmount}
                  minValue={minAmount}
                />
              ) : (
                <SkeletonButton stringLength={13} />
              )}
            </FilterCol>
            {hasMultipleRoles && (
              <FilterCol col="auto">
                {!isMetaDataLoading ? (
                  <CheckboxFilter
                    fieldName={t('ol.filters.role.fieldName')}
                    options={getRolesOptions(t)}
                    dropdownPosition="left"
                    onSubmit={handleRoleFilterSubmit}
                    defaultSelected={getDefaultSelected(
                      'filter.productType',
                      search,
                      getRolesOptions(t)
                    )}
                    onReset={handleRoleFilterReset}
                    dropdownWidth={250}
                  />
                ) : (
                  <SkeletonButton stringLength={13} />
                )}
              </FilterCol>
            )}
            <FilterCol col="auto">
              {!isMetaDataLoading ? (
                <CheckboxPure
                  checked={actionRequiredChecked}
                  value
                  label={t('ol.filters.action-required-checkbox.label')}
                  name="actionRequired"
                  onChange={handleActionRequiredFilterChange}
                />
              ) : (
                <SkeletonButton stringLength={17} />
              )}
            </FilterCol>
          </Row>
        </Col>
        <ResetCol col={2} textAlign="end">
          {filterCount ? (
            <ResetFilterButton
              data-target="reset-filters"
              filterCount={filterCount}
              onReset={resetAllFilters}
              useSuspense={false}
            />
          ) : null}
        </ResetCol>
      </Row>
    </Wrapper>
  )
}

OrderFilterContainer.propTypes = {
  /* eslint-disable-next-line react/forbid-prop-types */
  search: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  filterCount: PropTypes.number.isRequired,
  resetAllFilters: PropTypes.func.isRequired,
  hasMultipleRoles: PropTypes.bool.isRequired,
  maxAmount: PropTypes.string,
  minAmount: PropTypes.string,
  isMetaDataLoading: PropTypes.bool.isRequired,
  searchTradePartners: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  orderDisplayStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOf(Object.values(OrderDisplayStatus)),
    })
  ).isRequired,
  hasMetaDataError: PropTypes.bool.isRequired,
}

OrderFilterContainer.defaultProps = {
  maxAmount: null,
  minAmount: null,
}

export default React.memo(OrderFilterContainer, equals)
