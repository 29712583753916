import {EntityFactory} from '@modifi/redux'
import {createSelector} from 'reselect'
import {pathOr, pickBy, startsWith} from 'ramda'
import {OrderDisplayStatus} from '@modifi/enums'
import {selectSearchObj} from '@modifi/router'
import {getAuthenticatedAresClient} from '../../../lib'
import {normalizeSearchObj} from '../utils/normalizeSearchObj'
import {getOrderDisplayStatusOptions} from '../constants'

const REDUCER_KEY = 'plugins'

const ACTION_KEY = 'order-management/orders-meta'

const {actions, selectors, reducer} = EntityFactory({
  actionNamePrefix: ACTION_KEY,
  reducerPath: [REDUCER_KEY, 'client-filters'],
  fetchRequest: () => {
    const client = getAuthenticatedAresClient()
    return client.OrderFilterRanges.getFilterRanges().then(res => res.data())
  },
})

export default reducer

export const selectIsOrdersMetaDataLoading = selectors.selectIsLoading
export const selectHasMetaDataError = selectors.selectIsError

export const selectOrdersMetaData = selectors.selectEntity
export const aFetchOrdersMetaData = actions.fetch

export const selectHasActionRequiredOrders = createSelector(
  selectOrdersMetaData,
  pathOr(false, ['hasActionRequiredOrders'])
)

export const selectHasOrdersInProgress = createSelector(selectOrdersMetaData, ordersMeta =>
  ordersMeta?.orderDisplayStatuses?.includes(OrderDisplayStatus.IN_PROGRESS)
)

export const selectHasCompletedOrders = createSelector(selectOrdersMetaData, ordersMeta =>
  ordersMeta?.orderDisplayStatuses?.some(
    status =>
      [
        OrderDisplayStatus.CANCELLED,
        OrderDisplayStatus.COMPLETE,
        OrderDisplayStatus.DENIED,
        OrderDisplayStatus.REJECTED,
      ].indexOf(status) >= 0
  )
)

export const selectHasOrders = createSelector(
  selectOrdersMetaData,
  ordersMeta => ordersMeta?.roles?.length > 0
)

export const selectHasMultipleRoles = createSelector(
  selectOrdersMetaData,
  ordersMeta => ordersMeta?.roles?.length > 1
)

export const selectFirstOrderDate = createSelector(
  selectOrdersMetaData,
  ordersMeta => ordersMeta?.createdOn?.from
)

export const selectAvailableOrderDisplayStatusList = createSelector(
  selectOrdersMetaData,
  ordersMeta => ordersMeta?.orderDisplayStatuses || []
)

export const selectMaxAmount = createSelector(
  selectOrdersMetaData,
  ordersMeta => ordersMeta?.amount?.to
)

export const selectMinAmount = createSelector(
  selectOrdersMetaData,
  ordersMeta => ordersMeta?.amount?.from
)

export const selectNormalizeSearchObj = createSelector(selectSearchObj, normalizeSearchObj)

export const selectOrderDisplayStatusOptions = createSelector(
  selectAvailableOrderDisplayStatusList,
  (state, t) => t,
  (availableOrderDisplayStatusList, t) =>
    getOrderDisplayStatusOptions(t).filter(option =>
      availableOrderDisplayStatusList.includes(option.value)
    )
)

export const selectExistingFilters = createSelector(selectSearchObj, searchObj =>
  pickBy((val, key) => startsWith('filter', key) || key === 'hasActionRequired', searchObj)
)
