import {EntityFactory} from '@modifi/redux'
import {createSelector} from 'reselect'
import {selectSearchObj} from '@modifi/router'
import {compose, not, isEmpty} from 'ramda'
import {getAuthenticatedAresClient} from '../../../lib'
import {normalizeSearchObj} from '../utils/normalizeSearchObj'
import {getTradePartnerOrderStatusOptions} from '../constants'

const REDUCER_KEY = 'plugins'

const ACTION_KEY = 'order-management/trade-partner-orders-meta'

const {actions, selectors, reducer} = EntityFactory({
  actionNamePrefix: ACTION_KEY,
  reducerPath: [REDUCER_KEY, 'trade-partner-order-filters'],
  fetchRequest: () => {
    const client = getAuthenticatedAresClient()
    return client.TradePartnerOrderFilterRanges.getFilterRanges().then(res => res.data())
  },
})

export default reducer

export const selectIsOrdersMetaDataLoading = selectors.selectIsLoading
export const selectHasMetaDataError = selectors.selectIsError

export const selectTradePartnerOrdersMetaData = selectors.selectEntity
export const aFetchTradePartnerOrdersMetaData = actions.fetch

export const selectHasTradePartnerOrders = createSelector(
  selectTradePartnerOrdersMetaData,
  ordersMeta => compose(not, isEmpty)(ordersMeta?.createdOn)
)

export const selectHasActionRequiredTradePartnerOrders = createSelector(
  selectTradePartnerOrdersMetaData,
  ordersMeta => ordersMeta?.hasActionRequiredOrders
)

export const selectFirstOrderDate = createSelector(
  selectTradePartnerOrdersMetaData,
  ordersMeta => ordersMeta?.createdOn?.from
)

export const selectAvailableTradePartnerOrderStatusList = createSelector(
  selectTradePartnerOrdersMetaData,
  ordersMeta => ordersMeta?.tradePartnerOrderStatuses || []
)

export const selectMaxAmount = createSelector(
  selectTradePartnerOrdersMetaData,
  ordersMeta => ordersMeta?.amount?.to
)

export const selectMinAmount = createSelector(
  selectTradePartnerOrdersMetaData,
  ordersMeta => ordersMeta?.amount?.from
)

export const selectNormalizeSearchObj = createSelector(selectSearchObj, normalizeSearchObj)

export const selectTradePartnerOrderStatusOptions = createSelector(
  selectAvailableTradePartnerOrderStatusList,
  (state, t) => t,
  (availableOrderDisplayStatusList, t) =>
    getTradePartnerOrderStatusOptions(t).filter(option =>
      availableOrderDisplayStatusList.includes(option.value)
    )
)
