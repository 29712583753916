import {OrderDisplayStatus, ProductType} from '@modifi/enums'

export const PLUGIN_NAME = 'client-filters'

export const ACTION_REQUIRED = 'ACTION_REQUIRED'
export const IN_PROGRESS = 'IN_PROGRESS'
export const COMPLETE = 'COMPLETE'

export const quickFilterOptions = t => [
  {
    label: t('ol.nav.action-required'),
    value: ACTION_REQUIRED,
    filters: {
      hasActionRequired: true,
    },
  },
  {
    label: t('ol.nav.ongoing'),
    value: IN_PROGRESS,
    filters: {
      'filter.orderDisplayStatus': [
        OrderDisplayStatus.IN_PROGRESS,
        OrderDisplayStatus.PENDING,
        OrderDisplayStatus.OFFERED,
      ],
    },
  },
  {
    label: t('ol.nav.settled'),
    value: COMPLETE,
    filters: {
      'filter.orderDisplayStatus': [
        OrderDisplayStatus.CANCELLED,
        OrderDisplayStatus.COMPLETE,
        OrderDisplayStatus.DENIED,
        OrderDisplayStatus.FAILED,
      ],
    },
  },
]

export const getOrderDisplayStatusOptions = t => [
  {
    label: t('ol.filters.order-buyer-status.CANCELLED.label'),
    value: OrderDisplayStatus.CANCELLED,
  },
  {
    label: t('ol.filters.order-buyer-status.COMPLETE.label'),
    value: OrderDisplayStatus.COMPLETE,
  },
  {
    label: t('ol.filters.order-buyer-status.IN_PROGRESS.label'),
    value: OrderDisplayStatus.IN_PROGRESS,
  },
  {
    label: t('ol.filters.order-buyer-status.DENIED.label'),
    value: OrderDisplayStatus.DENIED,
  },
  {
    label: t('ol.filters.order-buyer-status.IN_REVIEW.label'),
    value: OrderDisplayStatus.IN_REVIEW,
  },
  {
    label: t('ol.filters.order-buyer-status.PENDING.label'),
    value: OrderDisplayStatus.PENDING,
  },
  {
    label: t('ol.filters.order-buyer-status.APPROVED.label'),
    value: OrderDisplayStatus.APPROVED,
  },
  {
    label: t('ol.filters.order-buyer-status.INCOMPLETE.label'),
    value: OrderDisplayStatus.INCOMPLETE,
  },
  {
    label: t('ol.filters.order-buyer-status.FAILED.label'),
    value: OrderDisplayStatus.FAILED,
  },
  {
    label: t('ol.filters.order-buyer-status.OFFERED.label'),
    value: OrderDisplayStatus.OFFERED,
  },
  {
    label: t('ol.filters.order-buyer-status.REJECTED.label'),
    value: OrderDisplayStatus.REJECTED,
  },
]

export const getRolesOptions = t => [
  {
    label: t('ol.filters.role.BUYER_FINANCING.label'),
    value: ProductType.BUYER_FINANCING,
  },
  {
    label: t('ol.filters.role.SELLER_FINANCING.label'),
    value: ProductType.SELLER_FINANCING,
  },
]

export const getSortOptions = t => [
  {
    label: t('ol.sort.order-creation-date.DESC.label'),
    value: 'DESC',
  },
  {
    label: t('ol.sort.order-creation-date.ASC.label'),
    value: 'ASC',
  },
]
