import React, {Component} from 'react'
import {
  CheckBoxFilterContainer,
  SearchFilterContainer,
  DateFilterContainer,
  AmountFilterContainer,
  CheckboxPure,
} from '@modifi/ui'
import {v4 as uuid} from 'uuid'
import qs from 'qs'
import {pickBy, startsWith, not} from 'ramda'
import {rejectEmpty} from '@modifi/utilities'
import * as PropTypes from 'prop-types'

import {OrderDisplayStatus} from '@modifi/enums'
import Header from './components/header'
import {MobileFilterWrapper, FilterContainer, SubmitButton} from './styles'
import {getDefaultSelected} from '../utils/filterValues'
import {formatMomentToString} from '../../../utils/formatMomentToString'
import {getCleanAmountFilters} from '../utils/getCleanAmountFilters'
import {getRolesOptions} from '../constants'

export default class MobileFilters extends Component {
  static propTypes = {
    search: PropTypes.shape({
      hasActionRequired: PropTypes.bool,
      'filter.amount.from': PropTypes.string,
      'filter.amount.to': PropTypes.string,
      'filter.createdOn.from': PropTypes.string,
      'filter.createdOn.to': PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    replaceUrl: PropTypes.func.isRequired,
    searchTradePartners: PropTypes.func.isRequired,
    orderDisplayStatusOptions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(OrderDisplayStatus)))
      .isRequired,
    hasMultipleRoles: PropTypes.bool.isRequired,
    maxAmount: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {}

  constructor(props) {
    super(props)
    const {search, orderDisplayStatusOptions, t} = this.props
    this.state = {
      orderStatusSelected: getDefaultSelected(
        'filter.orderDisplayStatus',
        search,
        orderDisplayStatusOptions
      ),
      hasActionRequired: search.hasActionRequired === true,
      fromAmount: search['filter.amount.from'],
      toAmount: search['filter.amount.to'],
      fromDate: search['filter.createdOn.from'],
      toDate: search['filter.createdOn.to'],
      tradePartners: getDefaultSelected('filter.tradePartnerExternalCompanyId', search),
      roles: getDefaultSelected('filter.productType', search, getRolesOptions(t)),
      nonce: uuid(),
    }
  }

  resetAllFilters = () => {
    this.setState({
      orderStatusSelected: [],
      hasActionRequired: null,
      fromAmount: null,
      toAmount: null,
      fromDate: null,
      toDate: null,
      tradePartners: [],
      roles: [],
      nonce: uuid(),
    })
    const {replaceUrl, location, search} = this.props
    replaceUrl({
      ...location,
      search: qs.stringify({
        ...pickBy((val, key) => not(startsWith('filter', key)), search),
        hasActionRequired: undefined,
      }),
    })
  }

  handleSubmit = () => {
    const {
      fromAmount,
      toAmount,
      orderStatusSelected,
      tradePartners,
      fromDate,
      toDate,
      roles,
      hasActionRequired,
    } = this.state
    const {replaceUrl, location, search} = this.props
    const cleanAmount = getCleanAmountFilters(fromAmount || null, toAmount || null)
    const searchParams = {
      'filter.orderDisplayStatus': orderStatusSelected.map(({value}) => value),
      'filter.tradePartnerExternalCompanyId': tradePartners.map(
        ({value, label}) => `${value}:${label}`
      ),
      'filter.createdOn.from':
        typeof fromDate === 'string' || !fromDate ? fromDate : formatMomentToString(fromDate),
      'filter.createdOn.to':
        typeof toDate === 'string' || !toDate ? toDate : formatMomentToString(toDate),
      'filter.amount.from': cleanAmount.from,
      'filter.amount.to': cleanAmount.to,
      'filter.productType': roles.map(({value}) => value),
      hasActionRequired,
    }
    replaceUrl({
      ...location,
      pathname: '/orders',
      search: qs.stringify(
        rejectEmpty({
          ...search,
          ...searchParams,
          page: 1,
        })
      ),
    })
  }

  getFilterCount = () => {
    const {
      fromAmount,
      toAmount,
      orderStatusSelected,
      tradePartners,
      fromDate,
      toDate,
      roles,
      hasActionRequired,
    } = this.state
    const count =
      orderStatusSelected.length +
      tradePartners.length +
      roles.length +
      (hasActionRequired ? 1 : 0) +
      (fromAmount ? 1 : 0) +
      (toAmount ? 1 : 0) +
      (fromDate ? 1 : 0) +
      (toDate ? 1 : 0)
    return count
  }

  handleTradePartnerSearch = searchString => {
    const {searchTradePartners} = this.props
    return searchTradePartners(searchString).then(tradePartners =>
      tradePartners.map(tradePartner => ({
        value: tradePartner.externalCompanyId,
        label: tradePartner.address?.companyName,
      }))
    )
  }

  render() {
    const {
      fromAmount,
      toAmount,
      orderStatusSelected,
      tradePartners,
      fromDate,
      toDate,
      roles,
      hasActionRequired,
      nonce,
    } = this.state

    const {
      replaceUrl,
      location,
      search,
      t,
      orderDisplayStatusOptions,
      maxAmount,
      hasMultipleRoles,
    } = this.props
    return (
      <MobileFilterWrapper>
        <Header
          onReset={this.resetAllFilters}
          filterCount={this.getFilterCount()}
          onClose={() => {
            replaceUrl({
              ...location,
              pathname: '/orders',
              search: qs.stringify({
                ...search,
              }),
            })
          }}
        />
        <FilterContainer>
          <CheckboxPure
            checked={hasActionRequired}
            label={t('ol.filters.action-required.mobile.label')}
            name="actionRequired"
            onChange={e => this.setState({hasActionRequired: e.target.checked})}
          />
        </FilterContainer>
        <FilterContainer>
          <CheckBoxFilterContainer
            options={orderDisplayStatusOptions}
            label={t('ol.filters.order-buyer-status.mobile.label')}
            defaultSelected={orderStatusSelected}
            onChange={newStatus => this.setState({orderStatusSelected: newStatus})}
          />
        </FilterContainer>
        <FilterContainer>
          <SearchFilterContainer
            key={nonce}
            label={t('ol.filters.trade-partner.mobile.label')}
            onSearch={this.handleTradePartnerSearch}
            defaultSelected={tradePartners}
            onChange={updatedTradePartners => this.setState({tradePartners: updatedTradePartners})}
            autoFocus={false}
          />
        </FilterContainer>
        <FilterContainer>
          <DateFilterContainer
            label={t('ol.filters.created-on.mobile.label')}
            fromDate={fromDate}
            toDate={toDate}
            onFromDateChange={from => this.setState({fromDate: from})}
            onToDateChange={to => this.setState({toDate: to})}
            toAnchorDirection="right"
            numberOfMonths={1}
            startCalendarFrom="1990-01-01"
          />
        </FilterContainer>
        <FilterContainer>
          <AmountFilterContainer
            key={nonce}
            label={t('ol.filters.order-amount.mobile.label')}
            fromAmount={fromAmount}
            toAmount={toAmount}
            onFromAmountChange={newAmount => this.setState({fromAmount: newAmount.value})}
            onToAmountChange={newAmount => this.setState({toAmount: newAmount.value})}
            maxValue={maxAmount}
          />
        </FilterContainer>
        {hasMultipleRoles && (
          <FilterContainer>
            <CheckBoxFilterContainer
              label={t('ol.filters.order-role.mobile.label')}
              options={getRolesOptions(t)}
              defaultSelected={roles}
              onChange={newRoles => this.setState({roles: newRoles})}
            />
          </FilterContainer>
        )}

        <SubmitButton block onClick={this.handleSubmit}>
          {t('ol.filters.mobile.cta-submit')}
        </SubmitButton>
      </MobileFilterWrapper>
    )
  }
}
