/* eslint-disable no-param-reassign */
import {CollectionFactory, combineReducers} from '@modifi/redux'
import {paymentBuyerStatus} from '@modifi/utilities/payment'
import {path, compose, add, has} from 'ramda'
import {aAddSnackBar} from '@modifi/plugin-snackbars'
import {getAuthenticatedAresClient} from '../../lib'
import {REDUCER_KEY} from './constants'
import keyWindowFromUrl from '../../utils/keyWindowFromUrl'

export const ACTION_KEY = 'ordersInfo/FETCH'

export {REDUCER_KEY} from './constants'

const {
  actions,
  reducer: collectionReducer,
  selectors,
} = CollectionFactory({
  actionNamePrefix: ACTION_KEY,
  reducerPath: [REDUCER_KEY, 'data'],
  fetchRequest: (state, params) => {
    const client = getAuthenticatedAresClient()
    // removing product type from request if both are selected
    if (has('filter.productType', params)) {
      if (params['filter.productType'].length > 1) {
        delete params['filter.productType']
      } else {
        // transforming to string
        // eslint-disable-next-line prefer-destructuring
        params['filter.productType'] = params['filter.productType'][0]
      }
    }
    if (params.hasActionRequired && params.hasActionRequired === 'false')
      delete params.hasActionRequired
    return client.Orders.all(params).then(response => {
      const {orders, _meta} = response.data()
      return {
        entities: orders.map(order => ({
          ...order,
          payments: order.payments.map(payment => ({
            ...payment,
            paymentBuyerStatus: paymentBuyerStatus(payment),
          })),
        })),
        meta: _meta,
      }
    })
  },
  entityPrimaryKeySelector: path(['externalId']),
})

export default combineReducers({
  data: collectionReducer,
})

export const aFetchOrders = () => (dispatch, getState) =>
  actions.fetch(keyWindowFromUrl(getState()))(dispatch, getState)

export const aAddOrder = order => (dispatch, getState) => {
  dispatch(actions.appendEntity(keyWindowFromUrl(getState()), order))
}

export const {
  selectById: selectOrderById,
  selectByKeyWindow: selectOrdersByKeyWindow,
  selectIsKeyWindowLoading,
  selectMetaByKeyWindow,
  selectCountByKeyWindow: selectOrdersCount,
  selectIsKeyWindowError,
  selectFetchedCount,
  selectFetchCountByKeyWindow,
} = selectors

export const selectTotalPages = compose(add(1), path(['maxPageIndex']), selectMetaByKeyWindow)
export const selectTotalOrdersCount = compose(path(['totalCount']), selectMetaByKeyWindow)
export const selectMaxPageIndex = compose(path(['maxPageIndex']), selectMetaByKeyWindow)

export const aShowErrorMessage = errorMessage => async dispatch => {
  dispatch(
    aAddSnackBar({
      type: 'alert',
      message: errorMessage,
    })
  )
}

export const fetchNOA = () => async externalOrderId => {
  const fileName = 'Notice of assignment'

  const client = getAuthenticatedAresClient()
  return client.NoticeOfAssignment.Download({externalOrderId})
    .then(res => [fileName, res.data(), 'application/pdf'])
    .catch(r => {
      throw r
    })
}
